import React, { useEffect, useState } from "react"
import axios from "axios"
import loading from "./Spinner-1.6s-200px (1).gif"
import { Container, Button, Row, Col, Form } from "react-bootstrap"

export default function Payment() {
  const [token, setToken] = useState(null)
  const [result, setResult] = useState(0)
  const [load, setLoad] = useState(false)

  //   useEffect(() => {
  //     axios
  //       .get("http://localhost:4001/payment")
  //       .then((response) => {
  //         // setToken(response.data.token)
  //         // window.location.replace(
  //         //   `https://accept.paymob.com/api/acceptance/iframes/778941?payment_token=${response.data.token}`
  //         // )
  //       })
  //       .catch((error) => {
  //         console.log(error.message)
  //       })
  //   }, [])

  const handleSubmit = () => {
    setLoad(true)
    axios
      .get(`https://e-prof.onrender.com/payment/${result}`)
      .then((response) => {
        setToken(response.data.token)
        window.location.replace(
          `https://accept.paymob.com/api/acceptance/iframes/778941?payment_token=${response.data.token}`
        )
      })
      .catch((error) => {
        // console.log(error.message)
      })
  }

  return (
    <Container>
      {load ? (
        <Container style={{ textAlign: "center" }}>
          <img src={loading} style={{ width: "40%" }} />
        </Container>
      ) : (
        <Row style={{ margin: "auto" }}>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Amount"
                onChange={(e) => {
                  // console.log(e.target.value + 3)
                  setResult(
                    parseFloat(
                      ((parseInt(e.target.value) + 3) / 0.96865).toFixed(2)
                    )
                  )
                }}
              />
              <Form.Text className="text-muted">
                Enter The Desired Amount.
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>To pay</Form.Label>
              <Form.Control
                type="text"
                placeholder="text"
                disabled
                value={result}
              />
            </Form.Group>

            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Submit
            </Button>
          </Form>
        </Row>
      )}
    </Container>
  )
}
